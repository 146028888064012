import {createWebHistory, createRouter} from "vue-router";
import routes from './routes';
import env from "@/env";
import store from "@/state/store";
import jwt from 'jwt-decode';
import {notifyError} from "@/components/composables/functions";

const router = createRouter({
    history: createWebHistory(),
    routes,
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {top: 0, left: 0};
        }
    },
});

router.beforeEach(async (routeTo, routeFrom, next) => {

    store.commit('api/getValues', {});
    store.commit('api/changeShowModalForm', false);

    const authRequired = routeTo.matched.some((route) => route.meta.authRequired);

    if (!authRequired) {
        return next();
    }

    if (localStorage.getItem('token') == null)  {
        next({name: 'login'});
    }

    // const authLevel = routeTo.matched.some((route) => route.meta.authLevel);
    // if (authLevel) {
    //     const user = JSON.parse(localStorage.getItem('user'));
    //     const level = user.category;
    //     console.log(level)
    //     if (ACCESS_BY_LEVEL[level].indexOf(routeTo.name) < 0) next({name: 'dashboard'});
    // }

    try {
        const token = localStorage.getItem('token');

        const decoded = jwt(token);
        const date = new Date().getTime() / 1000;

        if (decoded.exp < date) {
            localStorage.removeItem('token');
            notifyError('Sessão expirada!');
            next({name: 'login'});
        }
    } catch (e) {
        localStorage.removeItem('token');
        notifyError('Você precisa se autenticar!');
        next({
            name: 'login'
        });
    }

    next();
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
    try {
        for (const route of routeTo.matched) {
            await new Promise((resolve, reject) => {
                if (route.meta && route.meta.beforeResolve) {
                    route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
                        if (args.length) {
                            next(...args);
                            reject(new Error('Redirected'));
                        } else {
                            resolve();
                        }
                    });
                } else {
                    resolve();
                }
            });
        }
    } catch (error) {
        return;
    }
    document.title = routeTo.meta.title + ' | ' + env.title;
    next();
});

export default router;
