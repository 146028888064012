// import store from "@/state/store";

export default [
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/account/login"),
        meta: {
            title: "Login",
            // beforeResolve(routeTo, routeFrom, next) {
            //     if (store.getters["auth/loggedIn"]) {
            //         next({name: "dashboard"});
            //     } else {
            //         next();
            //     }
            // },
        },
    },
    {
        path: "/esqueceu-senha",
        name: "forgot-password",
        component: () => import("@/views/account/forgot-password.vue"),
        meta: {
            title: "Recuperar Senha",
        },
    },
    {
        path: "/recuperar-senha/:token",
        name: "change-password",
        component: () => import("@/views/account/change-password.vue"),
        meta: {
            title: "Resetar Senha",
        },
    },
    {
        path: "/logout",
        name: "logout",
        component: () => import("@/views/account/logout"),
        meta: {
            title: "Logout",
        },
    },
    {
        path: "/",
        name: "dashboard",
        meta: {
            title: "Dashboard",
            authRequired: true,
        },
        component: () => import("@/views/dashboard/index"),
    },
    {
        path: "/usuarios",
        name: "users",
        meta: {
            title: "Usuários",
            authRequired: true,
        },
        component: () => import("@/views/users/users"),
    },
    {
        path: "/alunos",
        name: "students",
        meta: {
            title: "Alunos",
            authRequired: true,
        },
        component: () => import("@/views/students/students.vue"),
    },
    {
        path: "/alunos/:id",
        name: "physical-assessment",
        meta: {
            title: "Avaliações Físicas",
            authRequired: true,
        },
        component: () => import("@/views/students/physical-assessment.vue"),
    },
    {
        path: "/horarios",
        name: "schedules",
        meta: {
            title: "Horários",
            authRequired: true,
        },
        component: () => import("@/views/schedules/index.vue"),
    },
];
