import http from '@/http'
import Router from "@/router";
import env from '@/env';
import {Forbidden} from "@/components/composables/functions";

export const state = {
    currentUser: {},
}

export const mutations = {
    setCurrentUser(state, newValue) {
        state.currentUser = newValue
    },
}

export const getters = {
    loggedIn(state) {
        return !!state.currentUser
    }
}

export const actions = {
    // eslint-disable-next-line no-unused-vars
    init({state, dispatch}) {
        dispatch('validate')
    },

    setCurrentUser({dispatch}, token) {
        localStorage.setItem('token', token)
        dispatch('initialize')
    },

    initialize({commit}) {
        const token = localStorage.getItem('token')

        if (!token) {
            return this.dispatch('reset')
        }

        http.get(env.api + 'me', {
                headers: {'Authorization': ` Bearer ${localStorage.getItem('token')} `}
            })
            .then(response => {
                commit('setCurrentUser', response.data.message.data);
                localStorage.setItem('token', response.data.message.token);
                Router.push('/');
            })
            .catch(error => {
                console.error(error);
                localStorage.removeItem('token');
                Router.push('/login');
                Forbidden(error);
            });
    },


    logout({commit}) {
        http
            .post(this.getters.api + 'logout')
            .then(() => {
                localStorage.removeItem('token');
                commit('setCurrentUser', null);
            })
            .catch(error => {
                console.error(error)
                // this.$message("Erro", "Erro ao realizar logout", "error");
            });
    },
}
